.footer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0; /* Stick to the bottom of the page */
  height: 65px;
  width: 98%;
  color: gray;
  background-color: #282828;
  padding: 20px;
}

.footer__left {
  flex: 0.3;
  display: flex;
  align-items: center;
  color: white;
  max-width: 300px;
}

.footer__albumLogo {
  height: 60px;
  width: 60px;
  margin-right: 20px;
  object-fit: contain;
}

.footer__songInfo > h4 {
  margin-bottom: 5px;
}

.footer__songInfo > p {
  font-size: 12px;
}

.footer__center {
  flex: 0.4;
  padding: 0 100px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
}

.footer__green {
  color: #1ed15e;
}

.footer__icon:hover,
.footer__green:hover {
  transition: transform 0.2s ease-in-out;
  transform: scale(1.2) !important;
}

.footer__right {
  flex: 0.3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  max-width: 300px;
}

.footer__right * .MuiSlider-root {
  color: #1ed15e;
}
