.login {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: #000;
}

.login > img {
  width: 100%;
  max-width: 500px;
}

.login > a {
  padding: 20px;
  border-radius: 99px;
  background-color: #1db954;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}
