.sidebarOption {
  /* Arrange Items: icon and title */
  display: flex;
  align-items: center;

  color: grey;
  height: 40px;
  cursor: pointer;
  padding-left: 20px;
  transition: 200ms color ease-in;
}

.sidebarOption:hover {
  color: white;
}

.sidebarOption_icon {
  padding-right: 10px;
}

.sidebarOption > p {
  margin-top: 10px;
  /* margin-left: 20px; */
  font-size: 14px;
}
